import React from "react"
import { FuzzyFunctionalComponent } from "@interfaces/common"
import { Switch } from "react-router-dom"

interface NavigationTabsPanels {}

const NavigationTabsPanels: FuzzyFunctionalComponent<NavigationTabsPanels> = ({ children }) => {
  return <Switch>{children}</Switch>
}

export default NavigationTabsPanels
