import React from "react"
import { FuzzyFunctionalComponent } from "@interfaces/common"

interface FuzeModalBannerProps {}

const FuzeModalBanner: FuzzyFunctionalComponent<FuzeModalBannerProps> = ({ children }) => (
  <div className="modal-banner">{children}</div>
)

export { FuzeModalBanner as ModalBanner }
