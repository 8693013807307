import React from "react"
import classnames from "classnames"
import { FuzzyFunctionalComponent } from "@interfaces/common"

interface PageHeaderTitleProps {
  className?: string
}

const PageHeaderTitle: FuzzyFunctionalComponent<PageHeaderTitleProps> = ({ children, className, ...props }) => {
  return (
    <h1 className={classnames("page__header-title", className)} {...props}>
      {children}
    </h1>
  )
}

export default PageHeaderTitle
