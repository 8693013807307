import React, { useEffect, useState } from "react"
import { FuzzyFunctionalComponent } from "@interfaces/common"
import classNames from "classnames"

interface ITabs {
  onTabChange?: Function
}

interface ITabsContext extends ITabs {
  activeTab: number
  onTabClick: Function
}

export const TabsContext = React.createContext<ITabsContext>(undefined)

const Tabs: FuzzyFunctionalComponent<ITabs> = ({ children, className, onTabChange, ...props }) => {
  const [activeTab, setActiveTab] = useState(0)

  let index: number = -1

  useEffect(() => {
    if (onTabChange) {
      onTabChange(activeTab)
    }
  }, [activeTab, onTabChange])

  const onTabClick = (index: number) => setActiveTab(index)

  return (
    <div className={classNames("fuze-tabs", className)} role="tablist" {...props}>
      <TabsContext.Provider value={{ activeTab, onTabClick }}>
        {React.Children.map(children, (element: JSX.Element) => {
          if (element?.type?.name !== "TabPanel") {
            return element
          }
          index++
          return React.cloneElement(element, { index })
        })}
      </TabsContext.Provider>
    </div>
  )
}

export default Tabs
