import React from "react"
import classnames from "classnames"
import PageInset from "./PageInset"
import { FuzzyFunctionalComponent } from "@interfaces/common"

interface PageHeaderProps {
  className?: string
  color?: string
}

const PageHeader: FuzzyFunctionalComponent<PageHeaderProps> = ({ children, className, color = "grey", ...props }) => {
  return (
    <div className={classnames("page__header", className, `page__header--${color}}`)} {...props}>
      <PageInset>{children}</PageInset>
    </div>
  )
}

export default PageHeader
