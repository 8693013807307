import React from "react"
import classnames from "classnames"
import { FuzzyFunctionalComponent } from "@interfaces/common"
import { MoreMenu } from "src"

interface PanelListItemMenuProps {
  menuName: string
}

const PanelListItemMenu: FuzzyFunctionalComponent<PanelListItemMenuProps> = ({
  children,
  className,
  menuName,
  ...props
}) => {
  return (
    <MoreMenu className={classnames("panel__menu", className)} menuName={menuName} {...props}>
      {children}
    </MoreMenu>
  )
}

export default PanelListItemMenu
