import React from "react"
import classnames from "classnames"
import { FuzzyFunctionalComponent } from "@interfaces/common"

export interface InternalToggleProps {
  ariaLabel?: string
  indeterminate?: boolean
}

const InternalToggle: FuzzyFunctionalComponent<InternalToggleProps> = ({
  ariaLabel,
  children,
  className,
  id,
  indeterminate,
  ...props
}) => {
  return (
    <div className={classnames("fuze-toggle-element", className)}>
      <input
        id={id}
        className="fuze-toggle-element__input"
        ref={(el) => el && (el.indeterminate = indeterminate)}
        type="checkbox"
        {...props}
      />
      <label className="fuze-toggle-element__label" htmlFor={id} aria-label={ariaLabel}>
        {children}
      </label>
    </div>
  )
}

export default InternalToggle
