import React from "react"
import classnames from "classnames"
import { FuzzyFunctionalComponent } from "@interfaces/common"
import InternalToggle, { InternalToggleProps } from "./InternalToggle"

const Checkbox: FuzzyFunctionalComponent<InternalToggleProps> = ({ className, children, ...props }) => {
  return (
    <InternalToggle className={classnames("fuze-checkbox", className)} {...props}>
      {children}
    </InternalToggle>
  )
}

export default Checkbox
