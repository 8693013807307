import React from "react"
import { FuzzyFunctionalComponent } from "@interfaces/common"
import classNames from "classnames"
import { Router } from "react-router-dom"
import { createBrowserHistory } from "history"

const history = createBrowserHistory()

interface NavigationTabs {}

const NavigationTabs: FuzzyFunctionalComponent<NavigationTabs> = ({ children, className, ...props }) => {
  return (
    <Router history={history}>
      <div className={classNames("fuze-tabs fuze-navigation-tabs", className)} role="tablist" {...props}>
        {children}
      </div>
    </Router>
  )
}

export default NavigationTabs
