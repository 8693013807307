import React, { useContext, useState } from "react"
import { FuzzyFunctionalComponent } from "@interfaces/common"
import classnames from "classnames"
import { NavLink } from "react-router-dom"

interface INavigationTab {
  activeClassName?: string
  exact?: boolean
  to: string
}

const NavigationTab: FuzzyFunctionalComponent<INavigationTab> = ({
  activeClassName,
  children,
  className,
  exact = false,
  to,
  ...props
}) => {
  return (
    <li className={classnames(className, "fuze-tabs__tab fuze-tabs__navigation-link", className)} {...props}>
      <NavLink to={to} activeClassName={activeClassName} exact={exact}>
        {children}
      </NavLink>
    </li>
  )
}

export default NavigationTab
