import React, { useContext, useMemo } from "react"
import classnames from "classnames"
import { ModalHeader, ModalHeaderProps } from "reactstrap"
import { ModalContext } from "./FuzeModal"
import { FuzzyFunctionalComponent } from "@interfaces/common"

const FuzeModalHeader: FuzzyFunctionalComponent<ModalHeaderProps> = ({ className, ...props }) => {
  const { color, toggle } = useContext(ModalContext)
  const gradient = useMemo(() => {
    switch (color) {
      case "secondary":
        return "gradient-dark-purple-to-light-purple"
      case "danger":
        return "gradient-red-to-orange"
      case "primary":
        return "gradient-blue-to-teal"
      case "gray":
      default:
        return ""
    }
  }, [color])

  return <ModalHeader className={classnames(gradient, className)} toggle={(): any => toggle(undefined)} {...props} />
}

export { FuzeModalHeader as ModalHeader }
