import React from "react"
import { FuzzyFunctionalComponent } from "@interfaces/common"
import classNames from "classnames"
import InternalTabPanel from "../Tabs/InternalTabPanel"
import { Route } from "react-router-dom"

interface INavigationTabsPanel {
  exact?: boolean
  path: string
}

const NavigationTabsPanel: FuzzyFunctionalComponent<INavigationTabsPanel> = ({
  children,
  className,
  exact = false,
  path,
  ...props
}) => {
  return (
    <Route exact={exact} path={path}>
      <InternalTabPanel
        className={classNames("fuze-tab-panel fuze-navigation-tabs-panel fuze-tab-panel--active", className)}
        {...props}
      >
        {children}
      </InternalTabPanel>
    </Route>
  )
}

export default NavigationTabsPanel
