import React from "react"
import classnames from "classnames"
import { ModalFooter } from "reactstrap"
import { FuzzyFunctionalComponent } from "@interfaces/common"

interface ModalFooterProps {}

const FuzeModalFooter: FuzzyFunctionalComponent<ModalFooterProps> = ({ children, className }) => {
  return <ModalFooter className={classnames(className)}>{children}</ModalFooter>
}

export { FuzeModalFooter as ModalFooter }
